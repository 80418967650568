import React from "react";

const BuyPageLeft = React.forwardRef((props, ref) => {
  return (
    <div className="demoPage" ref={ref}>
      <div class="anyalt-container">
        <iframe
          src="https://widget.anyalt.finance/?apiKey=pk_V3WXpHlJs4XE14jalwm21Dff4&theme=light"
          width="100%"
          height="800px"
          allow="accelerometer; autoplay; camera; gyroscope; payment; microphone"
        />
      </div>
    </div>
  );
});

export default BuyPageLeft;
