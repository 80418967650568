import React from "react";

const Learn3 = React.forwardRef((props, ref) => {
  return (
    <div className="demoPage flip_book_left_background" ref={ref}>
      <div className="flip_page_padding">
        <div
          style={{
            height: "15%",
            display: "flex",
            justifyContent: "center",
            width: "calc(100% - 30px)",
          }}
        >
          <h1
            className="SamuraiBlast gallery_title"
            style={{ textAlign: "center" }}
          >
            Iki Educates
          </h1>
        </div>
        <div className="page_content" style={{ height: "75%" }}>
          <div style={{ height: "100%" }}>
            <div
              className=""
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="learn_movie_element">
                <video
                  className="animation_video"
                  src="/assets/learn/twittervid.com_ikigaionsolana_35ed2d.mp4"
                  controls
                  preload="auto"
                  controlsList="nodownload"
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Learn3;
