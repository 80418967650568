import React, { useState, useEffect } from "react";
import { SocialIcon } from "react-social-icons";

const Social_Nav = () => {
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 1400);

  // useEffect(() => {
  //   const handleResize = () => {
  //     const currentIsMobile = window.innerWidth < 1400;
  //     if (currentIsMobile !== isMobile) {
  //       setIsMobile(currentIsMobile);
  //       toggleIcons();
  //     }
  //   };

  //   handleResize(); // Call once on mount
  //   window.addEventListener("resize", handleResize);

  //   return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
  // }, [isMobile]);

  const [isHidden, setIsHidden] = useState(window.innerWidth < 1400);
  console.log(isHidden);
  const toggleIcons = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div>
      <div className="social_link_component">
        <div
          style={{ paddingLeft: "10px" }}
          className={`element_social_link ${!isHidden ? "" : "grow-shrink"}`}
          onClick={toggleIcons}
        >
          <SocialIcon className="react_social_icon" />
        </div>
      </div>

      {!isHidden && (
        <>
          <div className="element_social_link">
            <SocialIcon
              target="blank"
              className="react_social_icon"
              url="https://t.me/IKIGAI_Token"
            />
          </div>
          <div className="element_social_link">
            <SocialIcon
              target="blank"
              className="react_social_icon"
              url="https://x.com/ikigaiOnSolana"
            />
          </div>
          <div className="element_social_link">
            <SocialIcon
              target="blank"
              className="react_social_icon"
              url="https://www.youtube.com/@ikigaionsolana"
            />
          </div>
          <div className="element_social_link">
            <SocialIcon
              target="blank"
              className="react_social_icon"
              url="https://www.instagram.com/ikigai.onsolana/?igsh=MTh4bmc4YmIxbmxvaQ%3D%3D&utm_source=qr"
            />
          </div>
          <div className="element_social_link">
            <SocialIcon
              target="blank"
              className="react_social_icon"
              url="https://www.tiktok.com/@ikigaionsolana?is_from_webapp=1&sender_device=pc"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Social_Nav;
