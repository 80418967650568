import "./Navbar.css";
import "./App.css";
import React, { useEffect, useRef, useState } from "react";

import MainBook from "./mainBook";
import FirstBook from "./firstBook";
import Social_Nav from "./pages/socialNav";
import FirstBookMobile from "./firstBookMobile";
import DexButton from "./pages/dexButton";

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="demoPage" ref={ref}>
      /* ref required */
      <h1>Page Header</h1>
      <p>{props.children}</p>
      <p>Page number: {props.number}</p>
    </div>
  );
});
const handleAudio = () => {
  const audio = new Audio("/assets/mixkit-birds-in-the-jungle-2434.wav"); // Adjust the path as necessary
  audio.play();
};

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds for demonstration

    return () => clearTimeout(timer);
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    console.log(isMobile);
  }, [isMobile]);

  // create an event listener
  useEffect(() => {
    //choose the screen size
    const handleResize = () => {
      if (window.innerWidth < 792) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div
      // onClick={handleAudio}
      className="main_background"
      style={{ height: "100vh", width: "100vw", position: "relative" }}
    >
      <div className="flip_book" style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            display: loading ? "" : "none",
          }}
        >
          {isMobile ? <FirstBookMobile /> : <FirstBook />}
        </div>
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            visibility: loading ? "hidden" : "",
          }}
        >
          <MainBook />
        </div>
        {/* <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
          }}
        >
          <MainBook />
        </div> */}
      </div>

      <div
        style={{
          width: "40%",
          position: "absolute",
          left: "0",
          bottom: "0",
          pointerEvents: "none",
        }}
      >
        <div
          className="display_flex_center"
          style={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          <img
            className="cat_gif"
            alt=""
            src="/assets/CatTail_FinalGIF.gif"
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div
        style={{
          width: "40%",
          position: "absolute",
          right: "0",
          bottom: "0",
          pointerEvents: "none",
        }}
      >
        <div
          className="display_flex_center"
          style={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          <img
            className="sakura_gif"
            alt=""
            src="/assets/Sakura_FinalGIF.gif"
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          right: "0",
          top: isMobile ? "15%" : "35%",
        }}
      >
        <div style={{ zIndex: "2" }}>
          <Social_Nav />
        </div>
      </div>
    </div>
  );
}
export default App;
